import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale.js';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#A50203',
    minHeight: '6em',
    textAlign: 'center',
    color: 'white',
    padding: '2em',
  },
  typo: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20px',
  },
}));

export default function Banner({ config }) {
  const classes = useStyles();
  const [locale] = useLocale();
  const lang = locale.split('-')[0];

  return (
    <Box role="alert" className={classes.box} component="div">
      <Typography className={classes.typo}>{config?.[lang]}</Typography>
    </Box>
  );
}
